import axios from '@axios'
import common from '@/libs/common'
import defaultService from './_default.service'

export default function previousService() {
  const { throwError } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`
  const defaultMethods = defaultService('Previos')

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  const fetchPrevious = (data, callback) => {
    axios
      .get(`${API_URL}/Previos`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createPrevious = (data, callback) => {
    axios
      .post(`${API_URL}/Previos`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchPreviousById = (idPrevious, callback) => {
    axios
      .get(`${API_URL}/Previos/${idPrevious}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchPreviousByGuid = (guid, callback) => {
    axios
      .get(`${API_URL}/Previos/Previo/${guid}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updatePrevious = (data, callback) => {
    axios
      .put(`${API_URL}/Previos/${data.previoId}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const partialUpdatePrevious = (idPrevious, data, callback) => {
    axios
      .patch(`${API_URL}/Previos/${idPrevious}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const deletePrevious = (idPrevious, callback) => defaultMethods.remove('/%{idPrevious}', { idPrevious }, true, callback)

  const fetchPreviousDocuments = (idPrevious, callback) => {
    axios
      .get(`${API_URL}/Previos/${idPrevious}/Documentos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPreviousDocument = (guid, callback) => {
    axios
      .get(`${API_URL}/Previos/Documentos/${guid}`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createPreviousDocument = (idPrevious, data, callback) => {
    axios
      .post(`${API_URL}/Previos/${idPrevious}/Documentos`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const deletePreviousDocument = (idPrevious, idPreviousDocument, callback) => {
    axios
      .delete(`${API_URL}/Previos/${idPrevious}/Documentos/${idPreviousDocument}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPreviousPartidas = (idPrevious, data, callback) => {
    axios
      .get(`${API_URL}/Previos/${idPrevious}/Partidas`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createPreviousPartida = (idPrevious, data, callback) => {
    axios
      .post(`${API_URL}/Previos/${idPrevious}/Partidas`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const updatePreviousPartida = (data, callback) => {
    axios
      .put(`${API_URL}/Previos/${data.previoId}/Partidas/${data.previoPartidaId}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const deletePreviousPartida = (idPrevious, idPreviousPartida, callback) => {
    axios
      .delete(`${API_URL}/Previos/${idPrevious}/Partidas/${idPreviousPartida}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPreviousPartidaPhotos = (idPrevious, idPartida, callback) => {
    axios
      .get(`${API_URL}/Previos/${idPrevious}/Partidas/${idPartida}/Fotos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createPreviousPartidaPhoto = (idPrevious, idPreviousPartida, data, callback) => {
    axios
      .post(`${API_URL}/Previos/${idPrevious}/Partidas/${idPreviousPartida}/Fotos`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const deletePreviousPartidaPhoto = (idPrevious, idPreviousPartida, idPreviousPartidaPhoto, callback) => {
    axios
      .delete(`${API_URL}/Previos/${idPrevious}/Partidas/${idPreviousPartida}/Fotos/${idPreviousPartidaPhoto}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPreviousPartidaPhoto = (guid, callback) => {
    axios
      .get(`${API_URL}/Previos/Partidas/Fotos/${guid}`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPreviousExcelTemplate = (data, callback) => {
    axios
      .get(`${API_URL}/Previos/ExportarPlantillaExcel`, { params: data, responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPreviousAccumulatedExcel = (data, callback) => {
    axios
      .get(`${API_URL}/Previos/ExportarPreviosAcumulados`, { params: data, responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createPreviousExcelTemplate = (data, callback, errorCallback) => {
    axios
      .post(`${API_URL}/Previos/ImportarPlantillaExcel`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => {
        // throwError(error)
        errorCallback(error.response.data)
      })
  }

  const fetchPreviousViewer = (idPrevious, callback) => {
    axios
      .get(`${API_URL}/Previos/${idPrevious}/Visor`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPreviousViewerByGuid = (guid, callback) => {
    axios
      .get(`${API_URL}/Previos/Previo/${guid}/Visor`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPreviousViewerPublicByGuid = (guid, callback) => {
    axios
      .get(`${API_URL}/Previos/Previo/${guid}/Visor/Publico`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPreviousViewerDownload = (data, callback) => {
    axios
      .post(`${API_URL}/Previos/${data.previoId}/VisorDescarga`, data, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  return {
    fetchPrevious,
    createPrevious,
    fetchPreviousById,
    fetchPreviousByGuid,
    updatePrevious,
    partialUpdatePrevious,
    deletePrevious,
    fetchPreviousDocuments,
    fetchPreviousDocument,
    createPreviousDocument,
    deletePreviousDocument,
    fetchPreviousPartidas,
    createPreviousPartida,
    updatePreviousPartida,
    deletePreviousPartida,
    fetchPreviousPartidaPhotos,
    createPreviousPartidaPhoto,
    deletePreviousPartidaPhoto,
    fetchPreviousPartidaPhoto,
    fetchPreviousExcelTemplate,
    fetchPreviousAccumulatedExcel,
    createPreviousExcelTemplate,
    fetchPreviousViewer,
    fetchPreviousViewerDownload,
    fetchPreviousViewerByGuid,
    fetchPreviousViewerPublicByGuid,
  }
}
